import './ResumeHeader.css';

export default function ResumeHeader() {
	return (
		<header>
			<h1><span class="firstname">Brian D</span> <span class="lastname">LeGrow</span></h1>
			<h2>Software Engineer/Tech Lead</h2>
            <ul>
                <li class="email"><span class="firstname">brian</span><span class="nospam"/><span class="lastname">legrow</span>.net</li>
                <li class="phone">484.919.5571</li>
                <li class="location">Greater Philadelphia, PA</li>
                <li class="web"><a href="https://www.brianlegrow.com"><span class="firstname">brian</span><span class="lastname">legrow</span>.com</a></li>
            </ul>
		</header>
	);
}