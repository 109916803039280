import { VerticalTimeline } from 'react-vertical-timeline-component';
import './VerticalTimeline.css';
import './Resume.css';

import ResumeHeader from './ResumeHeader.js';
import ResumeElement from './ResumeElement.js';

export default function Resume() {
	document.title = "Brian LeGrow | resume | software engineer, architect, tech lead";
	
	return (
		<main>
			<ResumeHeader />
			<VerticalTimeline>
				<ResumeElement highlight="true" type="work" organization="Fieldprint" title="Tech Lead/Sr Software Engineer" location="Mt Laurel NJ" date="Since 2023">
					<p>
- Responsible for the maintenance, development, and management of fingerprint kiosk software, a WPF touchscreen desktop application.<br />
- Responsible for the maintenance and development on public-facing appointment scheduling website, an Angular/C# SPA.<br />
- Responsible for the maintenance, development, and management of numerous C# microservices to implement the full product offerings and interface with external systems.<br />
<br />
- Tech tasks include code reviews/pull requests, greenfield development, brownfield development, and maintenance items.<br />
- Management tasks include oversight of offshore development team, long-term process improvements, and project management.<br />
<br />
<em>Primary technologies: C#, ASP.NET, WPF, SQL Server, Azure DevOps, Angular</em>
					</p>
				</ResumeElement>
				<ResumeElement type="work" organization="NBA" title="Tech Lead" location="Secaucus NJ" date="2023">
					<p>
- Responsible for the management, development and maintenance of the worldwide advertising planning and trafficking system.<br />
- Tasks included budgeting, executive reporting at multiple levels, oversight of offshore developers, product owner duties, long-term process improvements, as well as development of critical path product features and bug fixes.<br />
<br />
<em>Primary technologies: C#, ASP.NET, React, SQL Server, Azure DevOps, CheckMarx</em>
					</p>
				</ResumeElement>
				<ResumeElement type="work" organization="PrintMail Solutions" title="Sr Software Engineer" location="Newtown PA" date="2020-2023">
					<p>
- Mentored junior developers.<br />
- Maintained and expanded feature set of online banking product.<br />
- Architected changes to the online banking product by working with both internal and external stakeholders.<br />
- Developed data migration and warehousing efforts to control costs and improve application responsiveness.<br />
<br />
<em>Primary technologies: C#, ASP.NET, SQL Server</em>
					</p>
				</ResumeElement>
				<ResumeElement type="work" organization="Urbint" title="Software Architect" location="New York NY" date="2019-2020">
					<p>
Continuing my role from Opvantek in supporting the existing products after the exit/sale to Urbint.
					</p>
				</ResumeElement>
				<ResumeElement type="work" organization="Opvantek" title="Software Architect" location="Newtown PA" date="2018-2019">
					<p>
- Mentored three junior developers.<br />
- Architected, designed, and developed enterprise risk platform to replace aging legacy product.<br />
- Maintained and expanded development of SaaS risk analysis offering.<br />
<br />
<em>Primary technologies: C#, ASP.NET, Oracle, PostgreSQL, GeoServer, Amazon Web Services</em>
					</p>
				</ResumeElement>
				<ResumeElement type="work" organization="Opvantek" title="Sr Software Engineer" location="Newtown PA" date="2012-2018">
					<p>
- Architected, designed, and developed SaaS product to provide risk assessments of a live stream of risk inquiries from 811 ("Call Before You Dig") calls; 2019 revenue slightly over $1MM.<br />
- Helped produce, develop, improve, and optimize risk model in the SaaS product; the risk model identifies most likely/most costly failures for customers.<br />
- Architected, designed, and developed customer-facing risk analysis portal as part of SaaS offering.<br />
- Architected, designed, and developed programmatic interfaces for automated data exchanges as part of SaaS offering.<br />
- Exclusively architected and developed extensible suite of automated and manual GIS data entry products to facilitate customer data modernization efforts, est. annual revenue about $100K.<br />
- Designed and developed geocoding algorithm/provider with comparable fuzzy matching to Google Maps offering.<br />
- Implemented and maintained flagship products, including new feature development and software defect resolution.<br />
- Built new internal libraries to facilitate COM interop to allow old product encapsulation without rearchitecture/redevelopment.<br />
- Interfaced with customers to drive requirements gathering/design.<br />
<br />
<em>Primary technologies (2015-2018): C#, ASP.NET, Oracle, PostgreSQL, JavaScript, ESRI, AWS</em><br />
<em>Primary technologies (2012-2015): C#, VB.NET, COM Interop, Oracle, ESRI</em>
					</p>
				</ResumeElement>
				<ResumeElement type="work" organization="Opvantek" title="Software Engineer" location="Newtown PA" date="2008-2012">
					<p>
- Implemented and maintained flagship products, including new feature development and software defect resolution.<br />
- Interfaced with customers to drive requirements gathering/design.<br />
<br />
<em>Primary technologies: VB6, VB.NET, Oracle, ESRI, COM Interop</em><br />
					</p>
				</ResumeElement>
				<ResumeElement highlight="true" type="education" title="Drexel University" location="Philadelphia PA" date="2003-2008">
					<p>
Bachelor's of Science in Computer Science<br />
Specializations: Human-Computer Interaction (User Interfaces), Data Structures and Algorithms
					</p>
				</ResumeElement>
				<ResumeElement type="education" title="North Montco Technical Career Center" location="Lansdale, PA" date="2001-2003"/>
				<ResumeElement type="education" title="Methacton High School" location="Eagleville, PA" date="1999-2003"/>
			</VerticalTimeline>
		</main>
	);
}
